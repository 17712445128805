<template>
    <div class=" ">
        <v-app-bar class="navbar" fixed app color="#F2E3D5">
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon> 
            <VSpacer /><VSpacer />
            <router-link to="/">
                <img height="50" width="120" src="../assets/image.png"/>
            </router-link>
        </v-app-bar>

        <v-navigation-drawer v-model="drawer" class="fullheight" width="256" app color="#F2E3D5" location="right">
             
            <v-list dense nav>
                <v-list-item
                    v-for="item in items"
                    :key="item.title"
                    link
                    tag="router-link" 
                    :to="item.to"       
                >
                    <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <div class="grey lighten-4 fullheight pa-5">
            <router-view></router-view>
        </div>

        <v-footer flat color="#F2E3D5">
            <v-col class="text-center mt-4" cols="12">
                {{ new Date().getFullYear() }} — <strong>The Java Creacraft</strong>
            </v-col>
        </v-footer>
    </div>
</template>

<script>
export default 
{
    name: "Dashboard",
    data()
    {
        return {
            drawer: false,
            items:
            [
                { title: "Home", to: "/" },
                { title: "Products", to: "/products"},
                { title: "About Us", to: "/aboutus"},
                { title: "Contact", to: "/contact"},
            ],
        };
    },
};
</script>

<style scoped>
    .fullheight
    {
        min-height: 100vh !important;
    }
</style>
